<template>
  <common-dialog
    :title="!dataForm.jobId ?'新增' :'修改'"
    width="1000px"
    :close-on-click-modal="false"
    :is-visible.sync="visible"
  >
    <div style="border-top:#e8e8e8 solid 1px; padding:30px 40px 10px 50px;">
      <el-form ref="dataForm" :model="dataForm" :rules="dataRule" label-width="100px">
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="职位名称：" prop="position">
              <el-input v-model="dataForm.position" size="small" placeholder="职位名称" style="width:320px;" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="招聘人数：" prop="number">
              <el-input v-model="dataForm.number" type="number" size="small" placeholder="招聘人数(数字)" style="width:320px;" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="排序：" prop="sort">
              <el-input v-model="dataForm.sort" type="number" size="small" placeholder="排序(数字，越小越靠前)" style="width:320px;" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="工作地点：" prop="cityName">
              <el-input v-model="dataForm.cityName" size="small" placeholder="工作地点" style="width:320px;" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="工作性质：" prop="nature">
              <el-input v-model="dataForm.nature" size="small" placeholder="工作性质(全职\兼职\不限等)" style="width:320px;" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="经验要求：" prop="experience">
              <el-input v-model="dataForm.experience" size="small" placeholder="经验要求(1年\2-3年\3年以上\5年以上等)" style="width:320px;" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="学历要求：" prop="education">
              <el-input v-model="dataForm.education" size="small" placeholder="学历要求(不限\高中\中专\大专\本科\研究生\博士生等)" style="width:320px;" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="职位描述：" prop="description" style="margin-top:5px;">
          <el-input v-model="dataForm.description" type="textarea" maxlength="180" show-word-limit size="small" :autosize="{ minRows: 2, maxRows: 5}" placeholder="职位描述" style="width:786px;" />
        </el-form-item>
        <el-form-item label="职位详情：" prop="remarks" style="margin-top:21px;">
          <ueditor-wrap initial-frame-width="780" initial-frame-height="300" :ueditor-val="dataForm.remarks" @ueditorMsg="ueditorMsg" />
        </el-form-item>
      </el-form>
    </div>
    <div class="permission-dialog-footer">
      <el-button type="primary" size="small" @click="dataFormSubmit()">确定</el-button>
      <el-button size="small" style="margin-left:20px;" @click="cancel">取消</el-button>
    </div>
  </common-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      dataForm: {
        jobId: 0, //
        position: '', // 职位
        cityName: '', // 工作地点(城市)
        description: '', // 职位描述
        number: 0, // 招聘人数
        experience: '', // 经验要求(1年以上)
        education: '', // 学历要求(本科)
        nature: '', // 工作性质(全职)
        remarks: '', // 详情
        sort: 0 // 排序 越小越靠前
      },

      dataRule: {
        position: [{ required: true, message: '职位名称不能为空', trigger: 'blur' }],
        number: [{ required: true, message: '招聘人数不能为空', trigger: 'blur' }],
        sort: [{ required: true, message: '排序不能为空', trigger: 'blur' }],
        cityName: [{ required: true, message: '工作地点不能为空', trigger: 'blur' }],
        nature: [{ required: true, message: '工作性质不能为空', trigger: 'blur' }],
        experience: [{ required: true, message: '经验要求不能为空', trigger: 'blur' }],
        education: [{ required: true, message: '学历要求不能为空', trigger: 'blur' }],
        description: [{ required: true, message: '职位描述不能为空', trigger: 'blur' }]
      }
    }
  },
  created() {
  },
  methods: {
    ueditorMsg(data) {
      this.dataForm.remarks = data
      console.log(this.dataForm.remarks, '副本副本副本')
    },
    open(id) {
      this.visible = true
      this.dataForm.jobId = id
      this.dataForm.remarks = ''
      if (id > 0) {
        this.$http.post(`api/Job/GetSingleJob?jobId=${id}`, {}).then((data) => {
          if (data && data.code === 1) {
            this.dataForm = data.data
            this.ueditorMsg(this.dataForm.remarks)
          } else {
            this.$message.error(data.msg)
          }
        })
      } else {
        this.resetForm()
      }
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$http.post(`api/Job/${!this.dataForm.jobId ? 'AddJob' : 'UpdateJob'}`, this.dataForm)
            .then((data) => {
              if (data && data.code === 1) {
                this.$message({ message: '操作成功', type: 'success', duration: 700 })
                this.visible = false
                this.$emit('refreshDataList')
              } else {
                this.$message.error(data.msg)
              }
            })
        } else {
          // this.dataForm.taxSourceCityName = ''
        }
      })
    },
    resetForm() {
      this.dataForm = {}
    },
    cancel() {
      this.visible = false
    }
  }
}
</script>
